@import url("https://fonts.googleapis.com/css?family=Wire+One");

// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;

  font-weight: 100;
  font-family: 'Wire One', sans-serif;
  font-size: (1 * 12px);

  background-color: #eeeeee;
  color: #222222;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

html.inverted body {
  background-color: #000000;
  color: #cccccc;
}

@media (min-width: 500px) {
  body {
    font-size: (2 * 12px);
  }
}

@media (min-width: 650px) {
  body {
    font-size: (3 * 12px);
  }
}

@media (min-width: 800px) {
  body {
    font-size: (4 * 12px);
  }
}

@media (min-width: 950px) {
  body {
    font-size: (5 * 12px);
  }
}

@media (min-width: 1100px) {
  body {
    font-size: (6 * 12px);
  }
}

@media (min-width: 1250px) {
  body {
    font-size: (7 * 12px);
  }
}

@media (min-width: 1400px) {
  body {
    font-size: (8 * 12px);
  }
}

.prefix,
.entries,
.entry-container {
  line-height: 1.25;
  height: 1.25em;
}

.entry-container {
  transition: opacity 1s ease-in-out;
}

.entry {
  padding: 0 0.2em;
}
